<template>
    <div :class="$style.setEvents">
        <div :class="$style.desc">
            <div
                :class="[
                    $style.pic,
                    userInfo.area.toLocaleUpperCase() === 'EN'
                        ? $style.en
                        : userInfo.area.toLocaleUpperCase() === 'ZH'
                        ? $style.cn
                        : $style.jp
                ]"
            ></div>
            <div :class="$style.content">
                <div :class="$style.title">{{ $t('data_settings.install_event_title') }}</div>
                <div :class="$style.content_desc">{{ $t('data_settings.install_event_description') }}</div>
            </div>
        </div>
        <div :class="$style.code">
            <div :class="$style.code_desc">
                <div :class="$style.code_desc_title">{{ $t('data_settings.install_event_how') }}</div>
                <div :class="$style.code_desc_content">
                    {{ $t('data_settings.install_event_setting_code_description1') }}
                    <pt-link
                        v-if="userInfo.area === 'JP'"
                        type="primary"
                        size="small"
                        dom-type="a"
                        target="_blank"
                        :href="docUrl"
                        >{{ $t('data_settings.install_ptx_view_instruction') }}</pt-link
                    >
                    <br />
                    {{ $t('data_settings.install_event_setting_code_description2') }}
                </div>
            </div>
            <div :class="$style.code_content" v-if="profileInfo.profileSource">
                <code id="code_range">
                    <pre v-html="codeHTML"></pre>
                </code>
                <pt-button
                    size="small"
                    color="white"
                    type="outline"
                    :class="$style.btn_copy"
                    @click="copyValue('profile')"
                    >{{ $t('common.button_copy_code') }}</pt-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import codeConfig from './code.config';
import docsUrl from '@common/configs/docsUrl';

export default {
    name: 'SetEvents',

    data() {
        return {
            code: codeConfig.eventInstallCode,
            codeHTML: codeConfig.eventInstallCodeHTML,
            docUrl: docsUrl.eventSetting
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'userInfo'])
    },

    methods: {
        copyValue() {
            this.$copyText(this.code).then(this.copyValueSuccess, this.copyValueFail);
            let rangeDiv = document.querySelector('#code_range');
            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNode(rangeDiv);
            selection.removeAllRanges();
            selection.addRange(range);
        },

        copyValueSuccess() {
            this.$message.success(this.$t('common.tracking_code_copyright_tips'));
        },

        copyValueFail() {
            this.$message.error(this.$t('common.tracking_code_copyerror_tips'));
        }
    }
};
</script>
<style lang="scss" module>
@import './code_install.scss';
.setEvents {
    @extend .wrapper;
    .pic {
        &.en {
            background-image: url('~@assets/images/setup/event_code_en.svg');
        }
        &.jp {
            background-image: url('~@assets/images/setup/event_code.svg');
        }
        &.cn {
            background-image: url('~@assets/images/setup/event_code_cn.svg');
        }
    }
}
</style>
