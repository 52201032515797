var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.setEvents},[_c('div',{class:_vm.$style.desc},[_c('div',{class:[
                _vm.$style.pic,
                _vm.userInfo.area.toLocaleUpperCase() === 'EN'
                    ? _vm.$style.en
                    : _vm.userInfo.area.toLocaleUpperCase() === 'ZH'
                    ? _vm.$style.cn
                    : _vm.$style.jp
            ]}),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t('data_settings.install_event_title')))]),_c('div',{class:_vm.$style.content_desc},[_vm._v(_vm._s(_vm.$t('data_settings.install_event_description')))])])]),_c('div',{class:_vm.$style.code},[_c('div',{class:_vm.$style.code_desc},[_c('div',{class:_vm.$style.code_desc_title},[_vm._v(_vm._s(_vm.$t('data_settings.install_event_how')))]),_c('div',{class:_vm.$style.code_desc_content},[_vm._v(" "+_vm._s(_vm.$t('data_settings.install_event_setting_code_description1'))+" "),(_vm.userInfo.area === 'JP')?_c('pt-link',{attrs:{"type":"primary","size":"small","dom-type":"a","target":"_blank","href":_vm.docUrl}},[_vm._v(_vm._s(_vm.$t('data_settings.install_ptx_view_instruction')))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.$t('data_settings.install_event_setting_code_description2'))+" ")],1)]),(_vm.profileInfo.profileSource)?_c('div',{class:_vm.$style.code_content},[_c('code',{attrs:{"id":"code_range"}},[_c('pre',{domProps:{"innerHTML":_vm._s(_vm.codeHTML)}})]),_c('pt-button',{class:_vm.$style.btn_copy,attrs:{"size":"small","color":"white","type":"outline"},on:{"click":function($event){return _vm.copyValue('profile')}}},[_vm._v(_vm._s(_vm.$t('common.button_copy_code')))])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }